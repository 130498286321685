<!-- Nav items  -->
<ng-container
	[ngTemplateOutlet]="navItemTemplate"
	[ngTemplateOutletContext]="{
		$implicit: {
			isActive: aisleType.SHARE_RESIDENTIAL | activeNavItem: routeData?.aisle,
			route: ['/', sectionType.PROPERTIES_FOR_RENT, countyType.DEFAULT, aisleType.SHARE_RESIDENTIAL],
			label: 'n794',
			menu: shareMenu
		}
	}"
></ng-container>

<ng-container
	[ngTemplateOutlet]="navItemTemplate"
	[ngTemplateOutletContext]="{
		$implicit: {
			isActive: aisleType.RENT_RESIDENTIAL | activeNavItem: routeData?.aisle,
			route: ['/', sectionType.PROPERTIES_FOR_RENT, countyType.DEFAULT, aisleType.RENT_RESIDENTIAL],
			label: 'n251',
			menu: rentMenu
		}
	}"
></ng-container>

<ng-container
	[ngTemplateOutlet]="navItemTemplate"
	[ngTemplateOutletContext]="{
		$implicit: {
			isActive: sectionType.PROPERTIES_FOR_SALE | activeNavItem: routeData?.section,
			route: ['/', sectionType.PROPERTIES_FOR_SALE, countyType.DEFAULT, aisleType.SALE_RESIDENTIAL],
			label: 'n250',
			menu: buyMenu
		}
	}"
></ng-container>

<ng-container
	*ngIf="userData?.hasBusinessProfile !== '1'"
	[ngTemplateOutlet]="navItemTemplate"
	[ngTemplateOutletContext]="{
		$implicit: {
			route: ['/free-property-valuation'],
			label: 'n4093',
			isNew: true
		}
	}"
></ng-container>
<!-- end - Nav items -->

<!-- TEMPLATES -->

<!-- Nav item template -->
<ng-template #navItemTemplate let-data>
	<a
		mat-button
		disableRipple
		#navItemTrigger="matMenuTrigger"
		class="h-100 text-small-14px-regular fg-general-4 disabled-ripple head-link"
		[class.head-link-active]="data.isActive"
		[matMenuTriggerFor]="data.menu"
		[matMenuTriggerData]="{ navItemTrigger }"
		(mouseenter)="onParentMenuMouseEnter(navItemTrigger)"
		(mouseleave)="isParentMenuActive = false; onMenuMouseLeave(navItemTrigger)"
		(click)="onClickNavItem(data.route)"
		[routerLink]="data.route"
	>
		{{ data.label | translations }}
		<sup *ngIf="data?.isNew" class="bg-prim-2 px-2 rounded fg-basic-1 text-caption-medium ms-2 new-sup">NEW!</sup>
	</a>
</ng-template>

<!-- Menu item template -->
<ng-template #menuItemTemplate let-data>
	<div (mouseenter)="isParentMenuPanelOpen = true" (mouseleave)="isParentMenuPanelOpen = false; onMenuMouseLeave(data.navItemTrigger)">
		@for (item of data.sectionList; track item) {
			<a
				mat-menu-item
				class="text-body-regular fg-general-5 item-link"
				[routerLink]="data.routes | navItemRoute: item.reference"
				(click)="onClickNavItem(data.routes, item.reference)"
			>
				{{ item.translationKey | translations }}
			</a>
		}
	</div>
</ng-template>
<!-- end - Templates -->

<!-- MENUS -->

<!-- Share menu -->
<mat-menu #shareMenu="matMenu" [hasBackdrop]="false">
	<ng-template matMenuContent let-navItemTrigger="navItemTrigger">
		<ng-container
			[ngTemplateOutlet]="menuItemTemplate"
			[ngTemplateOutletContext]="{
				$implicit: {
					sectionList: sectionList | navItems: aisleType.SHARE_RESIDENTIAL,
					routes: ['/', sectionType.PROPERTIES_FOR_RENT, countyType.DEFAULT, aisleType.SHARE_RESIDENTIAL],
					navItemTrigger
				}
			}"
		></ng-container>
	</ng-template>
</mat-menu>

<!-- Rent menu -->
<mat-menu #rentMenu="matMenu" [hasBackdrop]="false">
	<ng-template matMenuContent let-navItemTrigger="navItemTrigger">
		<ng-container
			[ngTemplateOutlet]="menuItemTemplate"
			[ngTemplateOutletContext]="{
				$implicit: {
					sectionList: sectionList | navItems: aisleType.RENT_RESIDENTIAL,
					routes: ['/', sectionType.PROPERTIES_FOR_RENT, countyType.DEFAULT],
					navItemTrigger
				}
			}"
		></ng-container>
	</ng-template>
</mat-menu>

<!-- Buy menu -->
<mat-menu #buyMenu="matMenu" [hasBackdrop]="false">
	<ng-template matMenuContent let-navItemTrigger="navItemTrigger">
		<ng-container
			[ngTemplateOutlet]="menuItemTemplate"
			[ngTemplateOutletContext]="{
				$implicit: {
					sectionList: sectionList | navItems: sectionType.PROPERTIES_FOR_SALE,
					routes: ['/', sectionType.PROPERTIES_FOR_SALE, countyType.DEFAULT],
					navItemTrigger
				}
			}"
		></ng-container>
	</ng-template>
</mat-menu>
<!-- end - Menus -->
