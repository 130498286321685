import { Component, Input } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { AisleType, CountyType, SectionType } from '@core/enums';
import { Section } from '@core/models';
import { RouteData } from '@core/services/route.service';
import { NavItemRoutePipe } from '../../pipes/nav-item-route.pipe';
import { BreadcrumbsState } from '@shared/states/breadcrumbs.state';

@Component({
	selector: 'findqo-header-nav-items',
	templateUrl: './header-nav-items.component.html',
	styleUrl: './header-nav-items.component.scss',
	providers: [NavItemRoutePipe],
})
export class HeaderNavItemsComponent {
	constructor(private breadcrubmsState: BreadcrumbsState) {}

	@Input() sectionList: Section[] = [];
	@Input() routeData: RouteData;

	@Input() userData;

	isParentMenuActive = false;
	isParentMenuPanelOpen = false;
	activeNavMenutTrigger: MatMenuTrigger;

	readonly sectionType = SectionType;
	readonly aisleType = AisleType;
	readonly countyType = CountyType;

	onParentMenuMouseEnter(menuTrigger: MatMenuTrigger): void {
		if (!!this.activeNavMenutTrigger && this.activeNavMenutTrigger !== menuTrigger) {
			this.activeNavMenutTrigger.closeMenu();
		}

		menuTrigger.openMenu();

		this.activeNavMenutTrigger = menuTrigger;
		this.isParentMenuActive = true;
	}

	onMenuMouseLeave(menuTrigger: MatMenuTrigger): void {
		setTimeout(() => {
			if (!this.isParentMenuActive && !this.isParentMenuPanelOpen) {
				menuTrigger.closeMenu();
			}
		});
	}

	onClickNavItem(routes: string[], reference?: string): void {
		this.breadcrubmsState.setbreadcrumbsParams(['/', ...routes, ...(reference ? [reference] : [])]);
	}
}
